/**
 * Type: ページ
 * What: 夏・１周年記念 昆虫展
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Button } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import FUREAINOMORI_CATEGORIES from '../config/Fureainomori';
import ContentsListUnko from '../components/ContentsListUnko';
import mainImg2 from '../images/fureainomori/main-fureainomori.jpg';

const pageTitle = '昆虫展';
const pageDescription =
  'BOSS E・ZO FUKUOKAでふれあいの森 in 福岡開催♪カブトムシやノコギリクワガタをはじめとした様々な昆虫たちとふれあうことができる他、ヘラクレスオオカブトやギラファノコギリクワガタの展示コーナー、生体販売、飼育セットや昆虫グッズなどの販売も♪';
const pageSlug = 'fureainomori';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: mainImg2,
    noAnimation: true,
  },
];

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;

  const [isModal, setIsModal] = useState(-1);

  // モーダル展開
  const onClickModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModal(num);
  };

  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(-1);
  };

  // モーダルコンテンツ
  const ModalContent = () => {
    const theme =
      FUREAINOMORI_CATEGORIES[isModal] &&
      FUREAINOMORI_CATEGORIES[isModal].theme;
    const name =
      FUREAINOMORI_CATEGORIES[isModal] && FUREAINOMORI_CATEGORIES[isModal].name;
    const detail =
      FUREAINOMORI_CATEGORIES[isModal] &&
      FUREAINOMORI_CATEGORIES[isModal].detail;
    const img =
      FUREAINOMORI_CATEGORIES[isModal] && FUREAINOMORI_CATEGORIES[isModal].img;
    const colorClass =
      FUREAINOMORI_CATEGORIES[isModal] &&
      FUREAINOMORI_CATEGORIES[isModal].colorClass;
    const imgSlider =
      FUREAINOMORI_CATEGORIES[isModal] &&
      FUREAINOMORI_CATEGORIES[isModal].imgSlider;
    const sliderImg =
      FUREAINOMORI_CATEGORIES[isModal] &&
      FUREAINOMORI_CATEGORIES[isModal].sliderImg;
    return (
      <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
              {imgSlider ? (
                <SimpleSwiper data={sliderImg} />
              ) : (
                <Image filename={img || 'now-printing.jpg'} />
              )}
            </figure>
          </div>
          <div className="e-modal-content">
            <p className="theme">{theme}</p>
            <h3 className="ttl c-4">{name}</h3>
            <p dangerouslySetInnerHTML={{ __html: detail }} />
          </div>
        </div>
      </div>
    );
  };
  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    window.location.href =
      'https://e-zofukuoka.com/special/kabuto_kuwagata2024/';
    setFetchReady(true);
  }, []);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-12 bs-4">
        <div className="kv-wrap">
          <div className="mt-3 mb-3 sp-mb0 border-box-red sub-desc">
            <p className="text is-5 is-5-sp tac fwb">
              2021年のイベントは終了しました。
            </p>
            <p className="text is-5 is-5-sp tac fwb mt-5 red">
              2022年の昆虫イベント
            </p>
            <p className="text is-5 is-5-sp tac fwb mt-1 tx-link">
              <a className="red" href="/special/kabuto_kuwagata2022/">
                「見て！触って！カブト・クワガタの森」はこちら
              </a>
            </p>
          </div>
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-9">
                  <span>6</span>
                  <span>F</span>
                  <div className="floorPlace">うんこミュージアム隣</div>
                </div>
                <h2 className="ttl">
                  <span className="ttl-text ttl-color">
                    カブト・クワガタ
                    <br className="sp" />
                    ふれあいの森 in 福岡
                  </span>
                </h2>
              </div>
              <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>新型コロナウイルス感染防止の対策について</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section sc-topics">
        <div className="container">
          <div className="inner mb0">
            <h3 className="headline" id="area">
              <span>
                定番のカブトムシや
                <br className="sp" />
                ノコギリクワガタをはじめとした
                <br className="sp" />
                様々な昆虫たちとふれあうことができる他、
                <br />
                ヘラクレスオオカブトや
                <br className="sp" />
                ギラファノコギリクワガタの展示コーナー、
                <br />
                またお子様に人気の昆虫たちの生体販売、
                <br className="sp" />
                飼育セットや昆虫グッズなどの販売も行います！
              </span>
            </h3>
            <div className="sc-columns now-event">
              {FUREAINOMORI_CATEGORIES.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col col-4 ${categories.colorClass}`}
                  key={categories.name}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  onClick={(e) => onClickModal(e)}
                >
                  <ContentsListUnko {...categories} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="inner-slim">
          <div className="table-base">
            <table>
              <tbody>
                <tr>
                  <th>チケット料金</th>
                  <td>
                    <p>500円(税込)</p>
                    <p className="list-mark">
                      <span>※</span>
                      3歳以上有料、再入場不可
                    </p>
                    {url.ticketReady === '1' ? (
                      <p className="underline-link">
                        <a
                          href={`${url.ticket}#/${url.uuidEvent}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          チケット購入はこちら
                        </a>
                      </p>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="title is-5">注意事項</h4>
          <article>
            <ul className="list-base">
              <li className="attention">
                会場内、新型コロナウイルス感染症対策として「ふれあいコーナー」に関しましては15分毎の入場時間入替制とさせていただいております。
              </li>
              <li>
                当館ご利用の際のお怪我や損失・損害、その他トラブルなどにつきまして当館では一切責任を負いません。
              </li>
              <li>
                営業中止を除き、いかなる場合もチケットの払い戻しは行っておりません。
              </li>
              <li>
                チケットの転売は禁止しており、転売にて購入いただいたチケットではご入場頂けず、その場合も当館は一切の責任を負いません。
              </li>
              <li>
                お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。
              </li>
              <li>
                小学生以下のお子様のご入場には、20歳以上の保護者の同伴が必要です。
              </li>
              <li>
                イベントスペースでの喫煙、アメ・ガムを含む飲食はお断りしております。
              </li>
              <li>会場内にトイレ・休憩室はございません</li>
              <li>体調不良・泥酔状態でのご入場はお断りしております。</li>
              <li className="attention">
                ベビーカーではご入場いただけません。3Fベビーカー置き場をご利用ください。
              </li>
              <li>
                全長50cm以上の荷物は1Fもしくは3Fのコインロッカー、荷物置場をご利用ください
              </li>
              <li>補助犬を除き、動物を連れてのご入場はお断りしております。</li>
              <li>事前承諾のない商業目的の撮影や取材はお断りしております。</li>
            </ul>
            <h4 className="ttl-strip">持ち込み禁止物</h4>
            <p>
              危険物、臭気物、生花など
              <br />
              ペットボトル、水筒以外の蓋が無いドリンク類
              <br />
              その他、スタッフが危険であると判断した物品
            </p>
          </article>
        </div>
      </section>
      <ModalContent />
    </Layout>
  );
};

export default Template;
