const SLIDER_AREA01 = [
  {
    filename: `img-fureainomori-area01.jpg`,
  },
];
const SLIDER_AREA02 = [
  {
    filename: `img-fureainomori-area02.jpg`,
  },
];
const SLIDER_AREA03 = [
  {
    filename: `img-fureainomori-area03.jpg`,
  },
];
const SLIDER_AREA04 = [
  {
    filename: `img-fureainomori-area04.jpg`,
  },
];
const SLIDER_AREA05 = [
  {
    filename: `img-fureainomori-area05.jpg`,
  },
];
const SLIDER_AREA06 = [
  {
    filename: `img-fureainomori-area06.jpg`,
  },
];

const FUREAINOMORI_CATEGORIES = [
  {
    categories: 'team-lab', // カテゴリー名
    name: 'ヘラクレスオオカブト', // エリア名
    detail: '<p>展示コーナー</p>', // 説明
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: true,
    sliderImg: SLIDER_AREA01,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'コーカサスオオカブト', // エリア名
    detail: '<p>展示コーナー</p>', // 説明
    imgSlider: true,
    sliderImg: SLIDER_AREA02,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'ギラファノコギリクワガタ', // エリア名
    detail: '<p>展示コーナー</p>', // 説明
    imgSlider: true,
    sliderImg: SLIDER_AREA03,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'マンディブラリスフタマタクワガタ', // エリア名
    detail: '<p>展示コーナー</p>', // 説明
    imgSlider: true,
    sliderImg: SLIDER_AREA04,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'オオヒラタクワガタ', // エリア名
    detail: '<p>ふれあいコーナー</p>', // 説明
    imgSlider: true,
    sliderImg: SLIDER_AREA05,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'カブトムシ', // エリア名
    detail: '<p>ふれあいコーナー</p>', // 説明
    imgSlider: true,
    sliderImg: SLIDER_AREA06,
  },
];
export default FUREAINOMORI_CATEGORIES;

